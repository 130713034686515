@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GTWalsheimPro";
  src: url("./assets/fonts/GTWalsheimPro-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "GTWalsheimPro";
  src: url("./assets/fonts/GTWalsheimPro-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "GTWalsheimPro";
  src: url("./assets/fonts/GTWalsheimPro-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "GTWalsheimPro";
  src: url("./assets/fonts/GTWalsheimPro-Thin.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "GTWalsheimPro";
  src: url("./assets/fonts/GTWalsheimPro-Medium.ttf");
  font-weight: 500;
}

body {
  position: relative;
}
